.single_wedo .overlay_wedo:hover {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    margin: auto;
    background: rgba(230,43,74,.9);
    overflow: hidden;
    width: 0;
    height: 100%;
    content: "";
    
    opacity: 0;
   
    transition: all 2s ease-out;
}
*, :after, :before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.commonSection{
    position: relative;
}
.single_wedo{
    position: relative;
}
.single_wedo img{
    display: flex;
    width:353px;
    margin-left: -15px;
    margin-top: -15px;
    height: 350px;
    border-radius: 5px;
    margin-bottom: -15px;
    
}
.single_wedo .overlay_wedo {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    margin: auto;
    
    transition: all 2s;
}


.single_wedo .overlay_wedo a {
    height: 68px;
    color: #000;
    line-height: .8;
    font-size: 18px;
    letter-spacing: .72px;
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    text-align: center;
    padding: 26px 0;
    width: 240px;
    z-index: 9;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.carouse1 img{
    height: 400px;
}
@media (max-width:500px) {
    .carouse1 img{
        height: 200px;
    }
}
.text{
    transition: all 0.8s ease;
    color: #fff;
}
.text:hover{
    color: rgb(134, 131, 131)
}
@media (max-width:500px) {
    .sec{
        margin-top: -1px;
    }
}