.navs .container-fluid .nav-links {
  display: none;
}


.navs .nav-linked {
  background-color: #000000;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px;
}

@media (max-width:500px) {
  .navs .nav-linked {
    background-color: #000000;
    opacity: 0.7;
    width: 100%;
    height: 620px;
    text-align: center;
    padding: 40px;
    
  }


}

.navs .nav-linked .container {
  display: inline;

}

.navs .nav-linked .container h4 {
  display: flex;
  margin: 10px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}

.navs .nav-linked .container ul {
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}

@media (max-width:500px) {
  .navs .nav-linked .container {
    display: inline;

  }

  .navs .nav-linked .container h4 {
    display: flex;
    padding: 5px;
    font-size: 5px;
    justify-content: center;
    align-items: center;
  }

  .navs .nav-linked .container ul {
    display: flex;
    font-size: 5px;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    
  }
}

.navs .nav-linked li {

  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.navs .naved {
  display: none;
}

@media (max-width:500px) {
  .megaLinks {
    display: none;
  }

  .navs .nav-linked li {
    margin: 30px;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: -10px;
  }
}
@media (max-width:1200px) {
  .megaLinks {
    display: none;
  }
}

.navs .container-fluid .nav-link {
  float: left;
  display: flex;
  color: #000;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

.navs .container-fluid .nav-link li {
  margin-left: 40px;
  padding: 20px;
}




/* NAVBAR STYLES */
.navbar {
  justify-content: center;
  display: block;
  background-color: transparent;
}

.navbar img {
 
  width: 150px;
  height: 80px;
  padding-top: 0px;
  margin-top: 0px;
}

@media (max-width:500px) {
  .navbar {
    background-color: #000;
  }

  .carouse{
    margin-top: 102px;
  }
  
}

.navbar.active {
  background-color: #000;
  color: #fff;
}



.SubTitle {
  font-size: 12px;
  line-height: .8;
  color: #818181;
  letter-spacing: 1.2px;
  margin: 0 0 5px;
}

.secTitle {
  font-size: 50px;
  line-height: 60px;
  color: #000;
  letter-spacing: 2px;
  margin: 0 0 25px;
  white-space: pre-line;

}

.sec_desc {

  display: block;
}

@media (max-width: 500px) {
  .secTitle {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: block;
    right: 0;

  }
}

/* body{
    background-color: #e0e0e0;   
}  */

/* .centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards{
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.front{
  backface-visibility: hidden;
  background-color: red;
}

.back{
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.cardFlip{
  transform: rotateY(180deg);
} */

/* card flip styles  */
.flippable-card-container {
  height: 200px;
  width: 200px;
  perspective: 1000px;
}

@media (max-width:500px) {
  .row .cards {
    margin-left: 30%
  }
}

.row .cards {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1000ms;
}

.cards:hover {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  height: 100%;
  width: 100%;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 800;
  font-size: 60px;
  color: aliceblue;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
  position: absolute;
  backface-visibility: hidden;
}

.card-front {
  background-color: red;
  transform: rotateY(180deg);

  border-radius: 5px;

}

.card-back {
  background-color: #000;
  border: 1px solid grey;
  border-radius: 5px;

}

.card-front img {

  height: 64px;
  padding-left: 65px;
  margin-top: 50px;
  margin-bottom: 15px;


}

.card-back img {
  filter: brightness(9) invert(1);
  height: 64px;
  padding-left: 65px;
  margin-top: 50px;
  margin-bottom: 15px;


}
.mt-5 .sub_title{
 
  line-height: 20px;
}
@media (max-width:500px) {
  .mt-5 .col {
    
    line-height: 20px;
    margin-top: -30px;
    
  }
  .mt-5 .col ul{
    
    line-height: 20px;
    margin-top: 30px;
    
  }
}
/* para styles */

.sec_title,
.sub_title {
  font-weight: 400;
  text-transform: uppercase;
}

.sub_title {
  font-size: 12px;
  line-height: .8;
  color: #818181;
  letter-spacing: 1.2px;
  margin: 0 0 5px;
}

h1,
h2,
h3,
h4,
h5 h6 {
  color: #000;
  font-weight: 400;
  margin: 0 0 15px;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: Montserrat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

user agent stylesheet h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 767px) {
  .sec_title {
    font-size: 34px;
    letter-spacing: 1px;
    line-height: 50px;
  }
}

.MB_45 {
  margin-bottom: 45px;
}

.sec_title {
  font-size: 50px;
  line-height: 60px;
  color: #000;
  letter-spacing: 2px;
  margin: 0 0 25px;
  white-space: pre-line;
}

.sec_title,
.sub_title {
  font-weight: 400;
  text-transform: uppercase;
}

.sec_desc {
  margin: 0 0 54px;
  white-space: pre-line;
  color: #818181;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

}

/* best website desing division styles */

.best_website {
  margin-top: 100px;
  margin-bottom: 200px;
}

@media (max-width:'500px') {
  .best_website {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.img1,
.img2 {
  position: absolute;
  width: 300px;
  height: 300px;
}

.img1 {
  display: flex;
  /* -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  /* filter: grayscale(100%); */
  margin-left: 150px;

}

.img2 {
  display: flex;
  width: 300px;
  height: 300px;
  margin-left: 20px;
  margin-top: 150px;
  /* -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  /* filter: grayscale(100%); */
}

@media (max-width:500px) {

  .img1,
  .img2 {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-left: 20px;
  }
 

  .img1 {
    margin-left: 0px;
    margin-top: 20px;
    /* -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    /* filter: grayscale(100%); */ 
   
    margin-left: auto;
    margin-right: auto;
    margin-left: 20px;
    


  }

  .img2 {

    width: 200px;
    height: 200px;
    margin-left: 20px;
    margin-top: 300px;
    /* -webkit-filter: grayscale(100%); */
    /* Safari 6.0 - 9.0 */
    /* filter: grayscale(100%); */
    margin-left: 20px;
  
  }

}

.button {
  background-color: #000;
  color: #fff;
  width: 150px;
  height: 60px;

  text-align: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .button_out {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
}


.sec .sub {
  margin-top: 90px;
}

@media (max-width: 500px) {
  .sec .sub {
    margin-top: -20px;
  }

  .sec .sub_title {
    line-height: 15px;
    margin-bottom: -30px;
    margin-top: -10px;
  }
}

@media (max-width: 500px) {
  .contain {
    margin-bottom: -530px;
  }

}

/* work show case */



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width:500px) {
  .sec {
    margin-top: 550px;

  }

}


.img-wrap {
  position: relative;
  margin-bottom: 100px;
}

.play_video {

  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}

/* we are trusted by the more then */
.commonSection .container {

  position: relative;
  z-index: 2;
}

.CL_content {
  position: relative;
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div {
  display: block;
}

.commonSection .container .row .col-lg-12 img {
  width: 600px;
  height: 470px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin-top: -150px;

}

.commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 {
  margin-left: 450px;
  background-color: #fff;
  margin-top: 90px;
  padding-bottom: 80px;
  width: 748px;

}

.commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 h2 {

  font-size: 45px;
  margin-left: 100px;
  margin-top: 50px;


}

@media (max-width:500px) {
  .commonSection .container .row .col-lg-12 img {
    width: 100%;
    height: 100%;
    display: inline;
    margin-top: -150px;
  }

  .commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 {

    background-color: #fff;
    margin-top: 490px;
    padding-bottom: 50px;
    margin-left: -40px;
    display: inline;
    width: initial;


  }

  .commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 p {
    display: flex;
    margin-left: 70px;
    align-items: center;
    justify-content: center;
  }

  .commonSection .container .row .col-lg-12 .abc_inner .row .col-lg-7 .button_out {
    display: flex;
    justify-items: center;
    margin-left: 80px;

  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .abc_inner {
    margin-top: 0;
  }
}

.abc_inner {
  position: absolute;
  margin-top: -477px;
  z-index: 2;
}


/* background image */

.positions {
  position: relative;
}

.great_thing_img {
  background-image: url(./black_white.jpg);
  background-repeat: no-repeat;
  background-size: 100% 75%;
  filter: blur(10px);
  -webkit-filter: blur(10px);

  position: absolute;

}

.great_thing {
  position: absolute;
  text-align: center;

}

/* another way background image */

.testimonial {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url(./black_white1.jpg) no-repeat fixed 50%/cover;
  ;
  /* background: url(black_white.jpg) no-repeat fixed 50%/cover; */
  padding-bottom: 113px;
}

.commonSectioner {
  padding: 150px 0;
  position: relative;
  margin-bottom: 150px;
}

/* work_show case styles */

.work_show {

  margin-bottom: 80px;
}

@media (max-width:500px) {
  .work_show {
    margin-top: 500px;
    margin-bottom: 80px;
  }
  .workShowImage .row img {

    margin-left: -1%;

  }
}



/* expert peaple stylesheet */



/* Our clients styles */

.Clients .swip img {

  justify-content: center;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width:500px) {
  .Clients {
    margin-top: -650px;
  }

  .Clients .sub_title {
    margin-bottom: 10px;
  }

}

/* CARDS HOVER COLOR CHANGE STYLES */
@media (max-width:500px) {
  .MeetTeam{
    padding-bottom: 25px;
   
  }
}


@media screen and (max-width: 500px)  {
 .swip .employee{
  width: 90%;
  height: fit-content;
 }
 .swip .employee .overlay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(244, 64, 64, 0.78);
  opacity: 0;
  transition: 0.3s;
  border-radius: 18px;
  color: #fff;
 }
 .swip .employee .image img{
  border-radius: 18px;
 }
}

.swip .employee .image img{
  border-radius: 18px;
 
 }

.employee{
  width: 100%;
 
  position: relative;
  margin: 0 1em;
 
  background-size: cover;
  color: #fff;
  border-radius: 18px;
  
}
.employee .overlay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(244, 64, 64, 0.78);
  opacity: 0;
  transition: 0.3s;
  border-radius: 18px;
  color: #fff;

}
.employee:hover .overlay{opacity:1;}
.employee .links{
  padding: 0.6em 1.3em;
  text-decoration: none;
  color: #fff;
  border:2px #fff solid;
  letter-spacing: 1px;
  border-radius:8px;
}
.employee .links:hover{
  background: rgba(255, 255, 255, 0.3);
}
.employee .links, .employee h6, .employee h4{
  transition: 0.3s;
  transform: translateY(-20px);

}
.employee:hover .links,.employee:hover h6,.employee:hover h4{
  transform: translateY(0);
}

/* Services card styles */

.container .ServicesCards{
    transition: all 0.5s ease-in-out;
}
.container .ServicesCards:hover{
  box-shadow: 2px 2px 40px 2px grey;
}

.container .ServicesCards .Serviceslinks{
  transition: all 0.5s ease-in-out;
}

.container .ServicesCards .Serviceslinks:hover{
  color: red;
}

/* youtube styles */

.contain .video_popup .videoImg{
  color: red;
  width: 100px;
  height: 100px;

 }

.Youtube h2{
  font-size: 44px;
  font-weight: 400px;
}
@media (max-width:500px) {
  .Youtube{
  
    margin-top: 550px;
  }
  .Youtube h4{
    line-height: 1.2;
  }
  .Youtube h2{
    font-size: 20px;
    
  }

  .MeetTeam{
    margin-top: 550px;
   
  }
  .Clients{
    margin-top: -70px;
  }

  .contain .video_popup .videoImg{
   
   width: 50px;
   height: 50px;
  }
}

@media (max-width:500px) {
  .caroselCaption h3{
   
    font-size: 25px;
  }
}
/* carousel zoom effect */
.carouse .item>img{
  animation: zoom 15s;
  height: 550px;
}
@keyframes zoom{
  from{
    transform: scale(1,1);
  }
  to{
    transform: scale(1.1,1.1);
  }
}
@media (max-width:500px) {
  .carouse .item>img{
    animation: zoom 15s;
    height: 250px;
  }
}

/* .carouse .item img {
  width: 100%;
  animation: scale 3s linear infinite;
}
@keyframes scale{
  100%{
    width: 110%;
  }
}  */


/* hospital logos */

.container-fluid .swip .card img{
  width: 100%;
  height: 70%;
}